// Core
import React from "react"
import { StaticImage } from "gatsby-plugin-image"

// Layout
import { Layout } from "../layouts"

// Components
import { Wrapper, Seo } from "../components"

const BurgerKingPage = () => (
  <Layout>
    <Wrapper>
      <Seo title="Burger King" />
      <section className="brand">
        <h4 className="brand__heading">Customer story</h4>
        <h1 className="brand__title">
          Outloud and Burger King restaurants: a customer success story of
          scaling voice AI in the fast-food industry
        </h1>
        <p className="brand__text brand__text--italic">
          Miami, FL, Jul. 03, 2023 - Outloud.ai, a leading voice AI technology
          company, started a game-changing journey with one of the top Burger
          King franchisees in the Northeast, operating over 60 stores. Beginning
          with a 10-restaurant pilot, Outloud.ai's AI-powered system targeted an
          underexplored area of the fast-food and broader retail industry -
          upsell offerings.
        </p>
        <StaticImage
          className="brand__img"
          src="../assets/brand-store.png"
          alt="Burger King store"
          quality="90"
          layout="fullWidth"
          placeholder="blurred"
        />
        <p className="brand__text">
          "Upselling is a complex interaction," Sasha Miagkyi, founder and CEO
          of Outloud.ai explained.{" "}
          <span className="brand__text--highlight">
            "Operators often rely on intuition rather than data,
          </span>{" "}
          simply offering add-ons without knowing how often it was offered and
          the success rate. Our goal was to introduce precision and intelligence
          to this process."
        </p>
        <h3 className="brand__subtitle">
          The Pilot - A Data-Driven Approach to Upselling
        </h3>
        <p className="brand__text">
          In June 2022, the upsell offering rate of the Burger King operator was
          at 28%. Outloud.ai set out to understand the different dimensions of
          upselling – from upsizing meals and adding extras to promoting
          limited-time items, desserts, drinks, etc., based on the customers'
          preferences at different times of the day.
        </p>
        <p className="brand__text">
          "With our technology, we could not only increase the upsell rate but
          also understand what products customers preferred to be offered and
          when," Miagkyi said. By July 2023,{" "}
          <span className="brand__text--highlight">
            the upsell rate had dramatically increased from 28% to 80%.
          </span>
        </p>
        <p className="brand__text">
          "Doing a simple math of 80% upsell rate * 25% success rate * 15% of a
          check size,{" "}
          <span className="brand__text--highlight">
            we can see a 3% increase in revenue
          </span>{" "}
          simply from the upsell. That translates to tens of thousands of
          dollars," Miagkyi emphasized.
        </p>
        <section className="brand__row">
          <section className="brand__description">
            <h3 className="brand__subtitle">
              Success Beyond the Pilot - Scaling Across 60+ Stores
            </h3>
            <p className="brand__text">
              Impressed by the success of the pilot, the Burger King operator
              decided to implement Outloud.ai's technology across all their 60+
              stores. Today, the AI technology helps not just in upselling but
              also in motivating employees by providing trackable metrics that
              can lead to higher bonuses.
            </p>
            <p className="brand__text">
              "Our technology is about more than just revenue. It's about
              aligning the incentives of employees with the success of the
              business," Sasha pointed out. "By implementing upselling as a
              trackable metric, we help to increase employee retention rates.{" "}
              <span className="brand__text--highlight">
                Operators can pay employees sometimes 20-30% more
              </span>{" "}
              based on their performance."
            </p>
            <p className="brand__text">
              The successful collaboration between Outloud.ai and Burger King
              operator is more than just a success story. It's a testament to
              how voice AI technology can revolutionize the fast-food industry,
              offering greater customer service and enhancing operational
              efficiency.
            </p>
            <p className="brand__text">
              "With the right application of technology, we can uncover
              actionable insights that were previously impossible to glean.
              We're looking forward to bringing our voice AI technology to more
              businesses," concluded Sasha Miagkyi.
            </p>
          </section>
          <StaticImage
            className="brand__img"
            src="../assets/brand-good-alert.png"
            alt="Burger King good alert"
            quality="90"
            layout="fullWidth"
            placeholder="blurred"
          />
        </section>
        <p className="brand__text brand__text--italic">
          This information is provided for general informational purposes only,
          and publication does not constitute an endorsement. Outloud.ai does
          not warrant the accuracy or completeness of any information, text,
          graphics, links, or other items contained within this content.
          Outloud.ai does not guarantee you will achieve any specific results if
          you follow any advice herein. It may be advisable for you to consult
          with a professional such as a lawyer, accountant, or business advisor
          for advice specific to your situation. Individual results may vary.
        </p>
      </section>
    </Wrapper>
  </Layout>
)

export default BurgerKingPage
